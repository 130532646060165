const BASE_ENDPOINT = process.env.https://admin.villapinedo.nl;
const ENDPOINTS = {
  BASE: BASE_ENDPOINT,
  COLLECTIONS: `${BASE_ENDPOINT}/items`,
  FILES: `${BASE_ENDPOINT}/assets`,
  GRAPHQL: `${BASE_ENDPOINT}/graphql`,
  GRAPHQL_SYSTEM: `${BASE_ENDPOINT}/system/graphql`,
};

// todo: delete ENDPOINTS once everything is working against the new CMS
// URLS is replacing ENDPOINTS
export default ENDPOINTS;
